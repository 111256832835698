const STRINGS = {
  DEFAULT_AJAX_ERROR_TITLE: 'Error',
  DEFAULT_AJAX_SUCCESS_TITLE: 'Success',
  USER_ACTIVATION_SUCCESS: 'User activated successfully',
  USER_DEACTIVATION_SUCCESS: 'User deactivated successfully',
  USER_ENROLLMENT_MODE_CHANGE_SUCCESS: 'Updated course enrollment modes successfully',
  DEFAULT_AJAX_ERROR_MESSAGE: 'Something went wrong, Please try again later.',
};

export default STRINGS;
