import React from 'react';
import Stats from 'components/stats';
import CourseStats from 'analytics/courseStats';
import LearnerStats from 'analytics/learnerStats';
import {
  Styled,
  AnalyticsStats,
} from './styled';

const Analytics = () => (
  <Styled>
    <AnalyticsStats>
      <Stats />
    </AnalyticsStats>
    <CourseStats />
    <LearnerStats />
  </Styled>
);

export default Analytics;
